<template>
  <div class="">
    <custom-alert v-if="displayAlert==true" :message="alertMessage"
                  v-on:showedAlert="resetAlert" id="alert_box"></custom-alert>
    <div class="">
      <div class="page-banner">
        <img
          :src="this.getSiteProps('clubinnerpages.headerbanner')"
          style="width: 100%;"
          alt="page-banner"
        />
        <div class="page-title">
          <h5 class="text-white">Safe Sport Training / Background Screening</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mb-3 mt-4">
          <div class="card card-body pt-3 pb-3 driver-prog">
            <a href="#scrotobottom" class="text-right text-blue"
              >Skip to Background Screening Information</a
            >
            <h5 class="text-danger" style="text-decoration: underline;">
              Safe Sport Training
            </h5>
            <div class="text-center">
              <img
                src="/dist/img/SafeSport_Cobranding_Horizontal.png"
                style="width: 70%;"
                alt="page-banner"
              />
            </div>
            <p class="mt-3">
             <span class="font-h6"><strong> Who is required to complete SafeSport Training and background
              screening? </strong></span> – Refer to the
              <a
                href="https://assets.contentstack.io/v3/assets/blteb7d012fc7ebef7f/blt4647cae9fb0c791f/6532bcbcdcfb4f3fb7e58903/SafeSportComplianceChart.pdf"
                target="_blank"
                >USA Water Ski &amp; Wake Sports Safe Sport Compliance Chart.</a
              >
            </p>
            <p class="mt-3">
              USA Water Ski &amp; Wake Sports is committed to providing a safe
              and positive environment for its members and promotes an
              environment free from abuse and misconduct.
            </p>
            <p class="mt-3">
              SafeSport training is required for all active USA Water Ski &amp;
              Wake Sports members (18 years of age or older) who compete or
              participate in sanctioned tournaments or have regular contact with
              any athlete or other Participant who is a minor.
            </p>
            <p><a href="https://www.usawaterski.org/safe-sport" target="_blank">Safe Sport Frequently Asked Questions</a></p>
            <ul class="listitem">
              <li>
                <h6 class="mt-3"><strong>Requirements</strong></h6>
                <ul class="listitem">
                  <li>
                    Be at least 18 years of age or turning 18 during your
                    current membership period and be a current Active member of
                    USA Water Ski &amp; Wake Sports
                  </li>
                </ul>
              </li>
              <li>
                <h6 class="mt-3"><strong>Cost</strong></h6>
                <ul class="listitem">
                  <li>FREE to USA-WSWS Members</li>
                </ul>
              </li>
              <li>
                <h6 class="mt-3">
                  <strong>First-Time Training (Core Course)</strong>
                </h6>
                <ul class="listitem">
                  <li>
                    The training takes approximately between 75-90 minutes and
                    must be successfully completed to become Safe Sport trained.
                  </li>
                  <li>
                    You can start and stop the course at any time. The system
                    will remember where you left off.
                  </li>
                  <li>
                    You may not skip ahead or fast forward through the training.
                  </li>
                  <li>
                    There is a pre-test and a post-test. If you do not pass the
                    post-test, you can go back and re-take it again.
                  </li>
                  <li>
                    Instructions
                    <ul class="subinstruction">
                      <li class="">
                        Click on the link below to go to USA-WSWS member access
                        portal on the U.S. Center for Safe Sport’s website
                      </li>

                      <li class="">
                        Follow the on-screen instructions to create a new
                        account
                      </li>

                      <li>
                        Go to <strong class="text-danger">"My Courses"</strong> and choose the <strong class="text-danger">"Safe Sport Trained - NGB
                        1"</strong> course
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="row">
              <div class="col-md-12 text-center">
                <a href="javascript:void(0)" class="btn btn-primary mt-0" v-if="popupMessageEnable" v-b-modal.popupMessage>
                  First Time Safe Sport Training
                </a>
                <a
                  v-else
                  class="btn btn-primary mt-0"
                  target="_blank"
                  href="https://safesporttrained.org/?KeyName=FUSAwsws-h5MeRB#/"
                  >First Time Safe Sport Training</a
                >
                <b-modal
                    id="popupMessage"
                    centered
                    hide-header
                    ok-only
                    size="xl"
                    ok-title-html="<i class='fa fa-arrow-circle-right mr-2'></i>Ok, Noted"
                    @ok="redirectToSafeSport('https://safesporttrained.org/?KeyName=FUSAwsws-h5MeRB#/')"
                >
                  <p class="my-4" v-if="propSafeSport.popup && propSafeSport.popup.message" v-html="propSafeSport.popup.message"></p>
                </b-modal>
              </div>
            </div>

            <div class="card card-body mt-3">
              <h5>Refresher Training (Each year after Core Course)</h5>
              <p class="mt-3 mb-0">
                After completion of the Core Course, members are required to
                take a refresher course (estimated between 20-30 minutes to
                complete) annually to stay compliant (training is valid for
                one year). USA-WSWS will send a reminder email notification with
                a link to log into your Safe Sport account to complete the
                refresher course when your training is due for renewal.<strong> DO
                NOT</strong> use the first-time training (core course) option to renew
                your training.
              </p>
              <ul class="listitem parentsub">
                <li>
                  Instructions
                  <ul class="subinstruction">
                    <li class="">
                      Click on the link below to go to the log-in area of U.S.
                      Center for Safe Sport’s website
                    </li>

                    <li class="">
                     Go to <strong class="text-danger">“My Courses”</strong> and complete the applicable refresher training
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="row">
                <div class="col-md-12 text-center">
                  <a href="javascript:void(0)" class="btn btn-primary mt-0" v-if="popupMessageEnable" v-b-modal.popupMessage2>
                    Safe Sport Refresher Training
                  </a>
                  <a
                    v-else
                    class="btn btn-primary mt-0"
                    target="_blank"
                    href="https://safesporttrained.org/#/public-dashboard"
                    >Safe Sport Refresher Training</a
                  >
                  <b-modal
                      id="popupMessage2"
                      centered
                      hide-header
                      ok-only
                      size="xl"
                      ok-title-html="<i class='fa fa-arrow-circle-right mr-2'></i>Ok, Noted"
                      @ok="redirectToSafeSport('https://safesporttrained.org/#/public-dashboard')"
                  >
                    <p class="my-4" v-if="propSafeSport.popup && propSafeSport.popup.message" v-html="propSafeSport.popup.message"></p>
                  </b-modal>
                </div>
              </div>
            </div>

           <div class="card card-body mt-3">
              <h5>Resume SafeSport Training Course Already in Progress</h5>
              <p class="mt-3 mb-2 margin-bottom16">
                If you already have an account with SafeSport, have enrolled in a training course, and need to resume training already in progress, click on the link below to log into the U.S. Center for Safe Sport's website, go to <strong class="text-danger">"My Courses,"</strong> and resume the the course.
              </p>
              <div class="row">
                <div class="col-md-12 text-center">
                  <a href="javascript:void(0)" class="btn btn-primary mt-0" v-if="popupMessageEnable" v-b-modal.popupMessage3>
                    Resume SafeSport Training in Progress
                  </a>
                  <a
                    v-else
                    class="btn btn-primary mt-0"
                    target="_blank"
                    href="https://safesporttrained.org/#/login"
                    >Resume SafeSport Training in Progress</a
                  >
                  <b-modal
                      id="popupMessage3"
                      centered
                      hide-header
                      ok-only
                      size="xl"
                      ok-title-html="<i class='fa fa-arrow-circle-right mr-2'></i>Ok, Noted"
                      @ok="redirectToSafeSport('https://safesporttrained.org/#/login')"
                  >
                    <p class="my-4" v-if="propSafeSport.popup && propSafeSport.popup.message" v-html="propSafeSport.popup.message"></p>
                  </b-modal>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h5>
                Additional Resources - Minor and Parent Training (Not Required)
              </h5>
              <p class="mt-3">
                The Center also offers several free courses designed
                specifically for minors (preschool, elementary school, middle
                school and high school) along with a Parent’s Guide to
                Misconduct in Sport course designed for parents of athletes of
                all ages. This course explains the issues of misconduct in sport
                and helps parents ensure their children have a positive and safe
                sport experience.
              </p>
              <div class="row">
                <div class="col-md-12 text-center">
                  <a
                    class="btn btn-primary mt-0"
                    target="_blank"
                    href="https://www.teamusa.org/usa-water-ski/safesport-youth-training-courses"
                    >View all Safe Sport Trainings</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card card-body pt-3 pb-3 driver-prog">
            <!-- <h5 id="scrotobottom">Background Screening</h5> -->
            <h5 id="scrotobottom" class="text-danger" style="text-decoration: underline;">
              Background Screening
            </h5>
            <p class="mt-3">
              Background screens are required for all USA-WSWS staff, board of
              directors, Sport Discipline board of directors, level 1
              instructors, junior development coaches, U.S. international team
              members (athletes, coaches, staff, etc.), judges who are selected
              and approved by USA Water Ski &amp; Wake Sports or one of its
              Sport Disciplines to officiate at a National Championships event,
              or any IWWF World Championships or Multi Sports event (e.g. World
              Games), or Pan American Confederation Titled Event, and show
              directors and assistant show directors.
            </p>
            <p class="mt-3"><a href="https://www.usawaterski.org/safe-sport"
                target="_blank">Background Screening Frequently Asked Questions</a></p>
            <ul class="listitem">
              <li>
                <h6 class="mt-3"><strong>Requirements</strong></h6>
                <ul class="listitem">
                  <li>
                    Be at least 18 years of age and a current member of USA-WSWS
                  </li>
                  <li>Have a valid social security number</li>
                </ul>
              </li>
              <li>
                <h6 class="mt-3"><strong>First-Time Screening</strong></h6>
                <ul class="listitem parentsub">
                  <li>
                    Instructions:
                    <ul class="listitem subinstruction">
                      <li>
                        Click on the link below
                      </li>
                      <li>
                        Follow the applicable instructions to complete your
                        application, acknowledge disqualification criteria and
                        purchase the screening
                      </li>
                      <li>
                        Screening can take up to 7-10 business days to complete
                      </li>
                      <li>
                        Your screening status will be displayed on your "My Profile" page when logged into your Member Dashboard
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="row">
              <div class="col-md-12">
                <h6 class="mt-3"><strong>Cost - $30</strong></h6>
              </div>
              <div class="col-md-12 text-center pl-0">
                <a
                  class="btn btn-primary mt-0 background-screen"
                  :target="login_link?'_blank':''"
                  :href="login_link?'/member/ncsi':'javascript:void(0)'"
                  @click="openLink()"
                  >Purchase Background Screening</a
                >
<!--                <router-link-->
<!--                  to="/member/ncsi"-->
<!--                  class="btn btn-primary mt-0 background-screen"-->
<!--                  >Purchase Background Screening</router-link-->
<!--                >-->
              </div>
            </div>
            <ul class="listitem">
              <li>
                <h6 class="mt-3"><strong>Renewals</strong></h6>
                <ul class="listitem">
                  <li>
                    Background screenings are good for two years. USA Water Ski
                    &amp; Wake Sports will send you an email notification when
                    your screening is due for renewal.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 status_bar mt-4">
          <div class="right-sidebarlink">
            <pagesidebar
              :links="this.getSiteProps('memberdashboard.links')"
            ></pagesidebar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagesidebar from "../components/PageSidebar";
import CustomAlert from "../../../CustomAlert";
import moment from "moment";
import $ from "jquery";
export default {
  name: "drivers-program",
  components: { CustomAlert,pagesidebar },
  data() {
    return {
      prop_links: null,
      login_link: false,
      isMember: [],
      alertMessage:'',
      displayAlert:'',
      popupMessageEnable: false
    };
  },
  updated() {
    this.prop_links = this.getSiteProps("memberdashboard.links");
  },
  mounted() {
    const retry = setInterval(() => {
      if(this.propSafeSport) {
        if(this.propSafeSport?.popup?.enable){
          this.popupMessageEnable = moment(this.propSafeSport?.popup?.showUpto).isAfter(new Date());
        }
        clearInterval(retry);
      }
    }, 200);
  },
  methods: {
    openLink() {
      this.displayAlert = false;
      this.isMember = JSON.parse(localStorage.getItem("member"));
      const validThru = moment(this.isMember.membership_endtate).format('MM/DD/YYYY');
      const currentDate = moment().format('MM/DD/YYYY');
      const isExpired = moment(currentDate).isAfter(validThru);
        const age = this.isMember.age;
        if (!isExpired && age >= 18 && this.isMember.reg_membership_id !=7 && this.isMember.reg_membership_id !=3 && this.isMember.reg_membership_id !=4) {
          this.login_link = true;
        } else {
          this.login_link = false;
          this.alertMessage='Be at least 18 years of age and a current Active member' +
              '<br> of USA Water Ski & Wake Sports (Guest, GrassRoots, and Supporting Members are not eligible)';
          this.setDangerBg();
          this.displayAlert = true;
        }
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').addClass('alert-danger');
      });
    },
    redirectToSafeSport(url){
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= url;
      a.click();
    }
  },
  computed: {
    propSafeSport: function() {
      return this.getSiteProps("memberdashboard.safesports");
    }
  }
};
</script>
